import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { getCurrentRichContent } from "../helpers/richContentHelpers";

const visionPageTplData = (id) => {
  const data = useStaticQuery(
    graphql`
      {
        allWpPage {
          edges {
            node {
              id
              template {
                ... on WpTemplate_Vision {
                  visionRichContent {
                    # SECTION: Page Header
                    pageHeader {
                      header
                      text
                    }

                    # SECTION: Institutional Trading
                    institutionalTrading {
                      imageSection {
                        category
                        title
                        text
                        slug
                        variant
                        image {
                          ...wpImageFluid
                        }
                        items {
                          category
                          title
                          text
                          button {
                            variant
                            link {
                              target
                              title
                              url
                            }
                          }
                        }
                      }
                    }

                    # SECTION: Life Algotrader
                    lifeAtAlgotrader {
                      label
                      header

                      leadershipHeader
                      leadershipText
                      leadershipLink {
                        target
                        title
                        url
                      }
                      leadershipLogos {
                        image {
                          altText
                          localFile {
                            extension
                            childImageSharp {
                              gatsbyImageData(
                                formats: [PNG]
                                quality: 100
                                width: 180
                              )
                            }
                            childSvg {
                              content {
                                data
                              }
                            }
                          }
                        }
                      }

                      investorsHeader
                      investorsText

                      workingAtHeader
                      workingAtText
                      workingAtLink {
                        target
                        title
                        url
                      }
                    }

                    # SECTION: Our History
                    ourHistory {
                      title
                      text
                      timeline {
                        events {
                          ... on WpTemplate_Vision_Visionrichcontent_OurHistory_Timeline_Events_Story {
                            fieldGroupName
                            year
                            text
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  );

  return getCurrentRichContent(data, id, "visionRichContent");
};

export { visionPageTplData };
