// Default imports
import React, { useState, useRef, useEffect } from "react";
import { Container } from "react-bootstrap";

import { FixSvg } from "../helpers/svgHelpers";
import SliderArrow from "./SliderArrow";

import Wave from "./Wave";

const Timeline = ({ events }) => {
  // Add step number to all events
  let stepNumber = 0;
  events.forEach((event, index) => {
    // Increase step number
    if (/Events_Step$|Events_Story$/.test(event.fieldGroupName)) {
      ++stepNumber;
    }

    if (
      events.length > index + 1 &&
      /Events_Target$/.test(event.fieldGroupName)
    ) {
      events[index + 1].isAfterTarget = true;
      events[index - 1].isBeforeTarget = true;
    }

    event.stepNumber = stepNumber;
  });

  // States
  const [touchLastPos, setTouchLastPos] = useState(0);
  const [scrollPos, setScrollPos] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [isDraggable, setIsDraggable] = useState(false);

  const [wrapperWidth, setWrapperWidth] = useState(0);
  const [containerWidth, setContainerWidth] = useState(0);
  const [minScrollPos, setMinScrollPos] = useState(0);

  // References
  const timelineScrollable = useRef(null);
  const timelineWrapperTop = useRef(null);
  const nextArrow = useRef(null);
  const prevArrow = useRef(null);

  // After mount scripts
  useEffect(() => {
    setWrapperWidth(timelineScrollable.current.offsetWidth);
    setContainerWidth(timelineScrollable.current.parentNode.offsetWidth);
    setMinScrollPos(-1 * wrapperWidth + containerWidth);

    if (typeof window !== "undefined") {
      const wrapperWidth = timelineScrollable.current.offsetWidth;

      window.addEventListener("resize", () => {
        setScrollPos(0);
        setIsDraggable(wrapperWidth > window.innerWidth);
      });
      setIsDraggable(wrapperWidth > window.innerWidth);
    }
  });

  const changeScrollPos = (newScrollPos) => {
    if (isDraggable) {
      setScrollPos(
        newScrollPos > 0
          ? 0
          : newScrollPos < minScrollPos
          ? minScrollPos
          : newScrollPos
      );
    }
  };

  // Mouse events handlers
  const handleMouseLeave = (event) => {
    setIsDragging(false);
  };

  const handleMouseDown = (event) => {
    setIsDragging(true);
  };

  const handleMouseUp = (event) => {
    setIsDragging(false);
  };

  const handleMouseMove = (event) => {
    if (isDragging) {
      changeScrollPos(scrollPos + event.movementX);
    }
  };

  // Touch events handlers
  const handleTouchStart = (event) => {
    setTouchLastPos(event.changedTouches[0].pageX);
    setIsDragging(true);
  };

  const handleTouchEnd = (event) => {
    setIsDragging(false);
  };

  const handleTouchMove = (event) => {
    if (isDragging) {
      changeScrollPos(
        scrollPos - (touchLastPos - event.changedTouches[0].pageX)
      );
      setTouchLastPos(event.changedTouches[0].pageX);
    }
  };

  // Handle arrows click
  const handleArrowPrevClick = () => {
    changeScrollPos(scrollPos + 400);
  };

  const handleArrowNextClick = () => {
    changeScrollPos(scrollPos - 400);
  };

  return (
    <div className="timeline">
      <Container className="timeline__container">
        <div
          className={`timeline__wrapper ${isDragging ? "-dragging" : ""} ${
            isDraggable ? "-draggable" : ""
          }`}
          ref={timelineScrollable}
          style={{ left: scrollPos }}
          // Mouse handlers
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
          onMouseMove={handleMouseMove}
          onMouseLeave={handleMouseLeave}
          // Touch hadlers
          onTouchStart={handleTouchStart}
          onTouchEnd={handleTouchEnd}
          onTouchMove={handleTouchMove}
        >
          <div className="timeline__wrapper-top" ref={timelineWrapperTop}>
            {events
              .filter((event) => event.stepNumber % 2 === 1)
              .map((event, index) => (
                <TimelineEvent key={index} {...event} />
              ))}
          </div>

          <span className="timeline__line"></span>

          <div className="timeline__wrapper-bottom">
            {events
              .filter((event) => event.stepNumber % 2 === 0)
              .map((event, index) => (
                <TimelineEvent key={index} {...event} />
              ))}
          </div>
        </div>

        <SliderArrow
          ref={prevArrow}
          className={`timeline__arrow -prev ${
            !isDraggable || scrollPos >= -10 ? "-hidden" : ""
          }`}
          onClick={handleArrowPrevClick}
        />
        <SliderArrow
          ref={nextArrow}
          className={`timeline__arrow -next ${
            !isDraggable || scrollPos <= minScrollPos + 10 ? "-hidden" : ""
          }`}
          onClick={handleArrowNextClick}
        />
      </Container>
    </div>
  );
};

const TimelineEvent = ({ fieldGroupName, ...eventData }) => {
  switch (true) {
    case /Events_Step$/.test(fieldGroupName):
      return <TimelineStep {...eventData} />;

    case /Events_Target$/.test(fieldGroupName):
      return <TimelineTarget {...eventData} />;

    case /Events_Story$/.test(fieldGroupName):
      return <TimelineStory {...eventData} />;

    default:
      return <></>;
  }
};

const TimelineStep = ({
  title,
  text,
  stepNumber,
  isAfterTarget,
  isBeforeTarget,
}) => (
  <div
    className={`timeline__step ${stepNumber % 2 === 1 ? `-top` : "-bottom"} ${
      isAfterTarget ? "-after-target" : isBeforeTarget ? "-before-target" : ""
    }`}
  >
    <p className="timeline__number">
      {stepNumber < 10 ? `0${stepNumber}` : stepNumber}
    </p>
    <p
      className="timeline__title"
      dangerouslySetInnerHTML={{ __html: title }}
    />
    <p className="timeline__text" dangerouslySetInnerHTML={{ __html: text }} />

    <div className="timeline__wave-container wave-container">
      <Wave className="timeline__wave -deepblue250" variant={2} />
    </div>
  </div>
);

const TimelineStory = ({ year, text, stepNumber }) => (
  <div
    className={`timeline__step -story ${
      stepNumber % 2 === 1 ? `-top` : "-bottom"
    }`}
  >
    <p className="timeline__year">{year}</p>
    <p className="timeline__text" dangerouslySetInnerHTML={{ __html: text }} />
    <div className="timeline__wave-container wave-container">
      <Wave className="timeline__wave -deepblue250" variant={2} />
    </div>
  </div>
);

const TimelineTarget = ({ title, icon }) => (
  <div className="timeline__target">
    <FixSvg
      className="timeline__icon"
      svg={icon?.localFile.childSvg.content.data}
    />
    <p
      className="timeline__title"
      dangerouslySetInnerHTML={{ __html: title }}
    />
    <div className="timeline__wave-container wave-container">
      <Wave className="timeline__wave -deepblue250" variant={2} />
    </div>
  </div>
);

export default Timeline;
