// Default imports
import React from "react";
import Timeline from "../components/Timeline";
import GenericWideSection from "./GenericWideSection";

const OurHistory = ({ timeline, title, ...props }) => (
  <GenericWideSection vertical header={title} {...props}>
    <Timeline events={timeline.events} />
  </GenericWideSection>
);
export default OurHistory;
