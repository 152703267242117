// Default imports
import React from "react";
import { Row, Col } from "react-bootstrap";

import ButtonText from "./ButtonText";
import WpImage from "./WpImage";
import ContentBox from "./ContentBox";
import GenericWideSection from "./GenericWideSection";

const LifeAtAlgotrader = ({
  header,
  label,
  // LeaderShip
  leadershipHeader,
  leadershipLink,
  leadershipLogos,
  leadershipText,
  // Investors
  investorsHeader,
  investorsText,
  // Working At
  workingAtHeader,
  workingAtText,
  workingAtLink,
}) => {
  return (
    <GenericWideSection
      vertical
      contentClassName="pb-0"
      header={header}
      label={label}
    >
      <Row>
        <Col xs="24" lg="10">
          <div className="life-at-algotrader__content">
            <div className="life-at-algotrader__wrapper">
              <h3 className="life-at-algotrader__title">{leadershipHeader}</h3>
              <p className="life-at-algotrader__description">
                {leadershipText}
              </p>

              <ButtonText
                className="life-at-algotrader__link"
                {...leadershipLink}
                variant="red"
              >
                {leadershipLink.title}
              </ButtonText>
            </div>
            <div className="life-at-algotrader__wrapper">
              <h3 className="life-at-algotrader__title">{investorsHeader}</h3>
              <p className="life-at-algotrader__description">{investorsText}</p>
            </div>
            <Row className="life-at-algotrader__logos">
              {leadershipLogos.map(({ image }, index) => (
                <Col key={index} xs={12} md={8} xs={24}>
                  <div className="life-at-algotrader__logo">
                    <WpImage image={image} />
                  </div>
                </Col>
              ))}
            </Row>
          </div>
        </Col>

        <Col xs="24" lg={{ span: 10, offset: 2 }}>
          <ContentBox
            variant="blue-box"
            title={workingAtHeader}
            text={workingAtText}
            button={workingAtLink}
          />
        </Col>
      </Row>
    </GenericWideSection>
  );
};

export default LifeAtAlgotrader;
